@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: "Helvetica";
  src: url("../../public/fonts/Helvetica.woff") format("woff");
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../../public/fonts/Helvetica_Neue/HelveticaNeue-01.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue Medium';
  src: url('../../public/fonts/Helvetica_Neue/HelveticaNeue-Medium-11.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue Light';
  src: url('../../public/fonts/Helvetica_Neue/HelveticaNeue-Light-08.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@mixin helvetica {
  font-family: 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin helvetica-neue {
  font-family: 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

@mixin helvetica-neue-medium {
  font-family: 'Helvetica Neue Medium', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
}

@mixin helvetica-neue-light {
  font-family: 'Helvetica Neue Light', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

// $body-color: #212529;
$body-color: #000;
$yellow: #fee233;

body {
  color: $body-color;
  font-family: sans-serif;
  cursor: none;

  @include helvetica-neue;

  a {
    text-decoration: none;
    color: $body-color;
    transition: all 0.5s;
    cursor: none;

    &:hover {
      text-decoration: none;
      color: $body-color;
      box-shadow: inset 0 -2px 0 0 #212529;
      cursor: none;
    }
  }
}

.content {
  padding: 0 3vw;
  font-size: 2.4vw;

  h1.main-title {
    font-size: 12vw;
    // margin-top: 2vw;
    // margin-bottom: 2vw;
    height: 35vh;
    white-space: nowrap;
    margin-bottom: 0;
  }

  .opencall-mobile {
    position: absolute;
    left: 7vw;
    top: 17vh;

    a {
      img {
        width: 25vw;
        height: 25vw;
        transition: all 0.3s;
      }

      &:hover {
        box-shadow: none;
        cursor: none;

        img {
          transform: scale(1.1);
        }
      }
    }
  }

  p {
    margin-bottom: 3.1vw;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    .italic {
      font-family: Georgia, serif;
      font-style: italic;
    }
  }

  .text-content {
    padding-right: 2vw;
    padding-left: 0.8vw;
    height: 65vh;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 1vw;
      height: 62vh;
      width: 2px;
      border-left: 2px solid rgba(33, 37, 41, 0.6);
    }
  }

  .info-content {
    .title,
    .subtitle {
      margin-bottom: 3.6vw;
    }

    .opencall {
      position: absolute;
      right: 1vw;
      top: 1vw;

      a {
        img {
          width: 8vw;
          height: 8vw;
          transition: all 0.3s;
        }

        &:hover {
          box-shadow: none;
          cursor: none;

          img {
            transform: scale(1.1);
          }
        }
      }
    }

    hr {
      border: none;
      border-top: 2px solid rgba(33, 37, 41, 0.8);
      margin-top: 3.2vw;
      margin-bottom: 3.2vw;
      width: 4vw;
      margin-left: 0;
    }

    ul {
      padding: 0;
      list-style-type: none;

      li a {
        img {
          width: 2.4vw;
          height: 2.4vw;
          padding-bottom: 0.5vw;
          margin-left: -0.35vw;
          transition: all 0.3s;
        }

        &:hover {
          box-shadow: none;
          cursor: none;

          img {
            transform: scale(1.2);
          }
        }
      }
    }

    height: 65vh;
    overflow: hidden;
  }
}

#custom-cursor {
  position: fixed;
  height: 50px;
  width: 50px;
  margin: -25px 0 0 -25px;
  border-radius: 50%;
  // transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
  // left: 50%;
  // top: 50%;
  z-index: 104;
  background-color: rgba($yellow, 1);
  background-position: center;
  background-size: 0 0;
  transition: background-color 200ms ease-out, height 200ms ease-out, width 200ms ease-out;

  &.is-hovering {
    background-color: rgba($yellow, 0.6) !important;
  }
}

@media (max-width: 767px) {
  .content {
    overflow: hidden;
    padding: 2rem 1rem;
    font-size: 1.8rem;

    h1.main-title {
      font-size: 5.5rem;
      margin-bottom: 2rem;
      white-space: normal;
    }

    p {
      margin-bottom: 3rem;
      hyphens: auto;
    }

    .text-content {
      border-right: none;
      border-bottom: 2px solid rgba(33, 37, 41, 0.6);
      padding-right: 0;
      padding-left: 0;
      height: 100%;
      overflow: auto;

      &::after {
        display: none;
      }
    }

    .info-content {
      margin-top: 3rem;
      height: 100%;
      overflow: auto;

      .title,
      .subtitle {
        margin-bottom: 3rem;
      }

      hr {
        border: none;
        border-top: 1px solid rgba(33, 37, 41, 0.8);
        margin-top: 3rem;
        margin-bottom: 3rem;
        width: 4rem;
        margin-left: 0;
      }

      ul {
        li a {
          img {
            width: 2.2rem;
            height: 2.2rem;
            padding-bottom: 0.5rem;
            margin-left: 0;
          }
        }
      }
    }
  }

  #custom-cursor {
    height: 0;
    width: 0;
  }
}
